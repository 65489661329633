<script>
import axios from "axios";

import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
let user = JSON.parse(localStorage.getItem("user"));

/**
 * Register component
 */
export default {
  page: {
    title: "Sửa dịch vụ mua sắm hộ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      items: [
        {
          text: "Trang Chủ",
          href: "/",
        },
        {
          text: "Dịch vụ",
          href: "/danh-sach-dich-vu",
        },
        {
          text: "Sửa dịch vụ mua sắm hộ",
          active: true,
        },
      ],

      service: {
        name: "Dịch vụ mua sắm hộ",
        type: 1,
        shopping: {
          link: null,
          price: 0,
          tax: 0,
          unit: "%",
        },
      },
      submitted: false,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    // Try to register the service in with the email, name
    // and password they provided.

    tryToUpdateIn() {
      this.submitted = true;

      // stop here if form is invalid
      axios({
        url: process.env.VUE_APP_SERVER_URL + "/service/update",
        method: "post",
        headers: {
          authorization: user.token,
        },

        data: this.service,
      })
        .then(({ data }) => {
          this.$toasted.success("Sửa dịch vụ thành công");
        })
        .catch((error) => {
          this.$toasted.error("Sửa dịch vụ có lỗi");
        });
    },
  },

  created() {
    if (!this.$route.query.id) {
      this.$router.push("/");
    }
    let seft = this;
    let _id = this.$route.query.id;

    axios({
      url: process.env.VUE_APP_SERVER_URL + "/service/info",
      method: "post",
      headers: {
        authorization: user.token,
      },

      data: {
        _id: _id,
      },
    })
      .then(({ data }) => {
        if (data) {
          console.log(data);
          this.service = data;
        } else {
          this.$toasted.error("Không tìm thấy dịch vụ phù hợp");
          this.$router.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
        this.$toasted.error("Không tìm thấy dịch vụ phù hợp");
        // this.$router.push("/");
      });
  },
};
</script>
<style>
.b-form-tag-content {
  border: 1px solid #e5e5e5;
  background: #f8f9fa;
  color: #000;
  border-radius: 2px;
  padding: 5px;
  text-transform: capitalize;
}

.b-form-tag-remove {
  border: 0px;
  margin-left: 2px;
}
</style>
<template>
  <Layout>
    <PageHeader :items="items" />
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <div class="card overflow-hidden">
          <div class="card-body pt-0">
            <b-form class="my-2 p-2" @submit.prevent="tryToUpdateIn">
              <b-form-group
                class="mb-3"
                id="service-name"
                label="Tên dịch vụ"
                label-for="name"
              >
                <b-form-input
                  v-model="service.name"
                  type="text"
                  debounce="1000"
                  placeholder="Nhập tên"
                  aria-required=""
                ></b-form-input>
              </b-form-group>

              <b-form-group
                class="mb-3"
                id="service-name"
                label="Link mua sắm"
                label-for="link"
              >
                <b-form-input
                  v-model="service.shopping.link"
                  type="text"
                  debounce="1000"
                  placeholder="Nhập link mua sắm"
                  aria-required=""
                ></b-form-input>
              </b-form-group>
              <div class="px-3">
                <b-row>
                  <b-col class="md-3">
                    <b-form-group
                      class="mb-3"
                      label="Thuế"
                      label-for="service-shopping-tax"
                    >
                      <b-form-input
                        v-model="service.shopping.tax"
                        debounce="1000"
                        type="number"
                        step="0.01"
                        min="0.00"
                        max="1000000.00"
                        :placeholder="'Nhập thuế của '"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="md-3">
                    <b-form-group>
                      <span>Tính thuế theo </span> {{ service.shopping.unit }}
                      <div class="py-2">
                        <b-form-select
                          class="px-2 form-control"
                          v-model="service.shopping.unit"
                          size="md"
                          :options="['$', '%']"
                        ></b-form-select>
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col>
                    <b-form-group>
                      <span>Giảm giá </span>
                      <div class="py-2">
                        <b-form-input
                          v-model="service.shopping.disc"
                          debounce="1000"
                          step="0.01"
                          min="0.00"
                          max="1000000.00"
                          type="number"
                          placeholder="Thuế cho loại sản phẩm"
                        ></b-form-input>
                      </div>
                    </b-form-group>
                  </b-col> -->

                  <b-col>
                    <b-form-group>
                      <span>% nhân viên</span>
                      <div class="py-2">
                        <b-form-input
                          v-model="service.shopping.salary"
                          debounce="500"
                          type="number"
                          step="0.01"
                          min="0.00"
                          max="1000000.00"
                          placeholder="Nhập dữ liệu"
                        ></b-form-input>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <div class="mt-4 d-grid">
                <b-button type="submit" variant="primary" class="btn-block"
                  >Sửa dịch vụ</b-button
                >
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>